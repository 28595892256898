.margin {
  margin-top: 50%;
}

.title {
  font-size: 50px;
  letter-spacing: -0.6px;
  line-height: 1.1;
  text-shadow: -1px -1px 1px #000;
}

.back {
  /* background-image: url("../../../assets/news/miskonsepsi-tentang-bahagia.jpg"); */
  background-size: cover;
  position: absolute;
  background: linear-gradient(rgba(20, 20, 20, 0.3), rgba(20, 20, 20, 0.3)),
    url("../../../assets/news/miskonsepsi-tentang-bahagia.jpg") no-repeat center
      center fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 70vh;
  padding-top: 15%;
  padding-right: 50px;
  color: #fff;
  text-align: center;
}
