.profiles-container {
  margin: -3rem;
  padding: 0 4rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
}

.profiles {
  display: flex;
  align-items: center;
  padding: 0 2rem;
  gap: 1rem;
  overflow: hidden;
}

.profiles-blur-1 {
  width: 32rem;
  height: 23rem;
  top: 6rem;
  left: 0rem;
}

.profiles-blur-1 {
  width: 32rem;
  height: 23rem;
  top: 10rem;
  right: 0rem;
}

.profile {
  display: flex;
  flex-direction: column;
  background: white;
  color: var(--textColor);
  gap: 2rem;
  padding: 1.5rem;
  width: 16.5rem;
  min-height: 543px;
}

.profile > img {
  fill: var(--orange);
  width: 100%;
  background-color: var(--gray);
  border-radius: 70px;
  height: 13rem;
}

.profile > :nth-child(2) {
  font-size: 1.5rem;
  font-weight: bold;
}

.profile > :nth-child(2):hover {
  color: var(--orange);
  cursor: pointer;
}

.profile > :nth-child(3) {
  font-size: 0.8rem;
}
.profile > :nth-child(4) {
  font-size: 0.8rem;
}

.profile .buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 80%;
  border-radius: 45%;
  bottom: 2rem;
}

.profile .buttons .btn {
  background-color: var(--orange) !important;
  color: var(--lightgray);
  border-radius: 45px;
}

.specialis {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.spesial {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.spesial > img {
  width: 1rem;
  height: 1rem;
}

@media screen and (max-width: 768px) {
  .profiles {
    flex-direction: column;
  }

  .profiles > :nth-child(2) {
    transform: none;
  }
}
