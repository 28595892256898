.footer-container {
  position: relative;
  margin-top: 10rem;
}

.flexSB {
  display: flex;
  justify-content: space-between;
}

.box > ul {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.box > ul > a:hover {
  cursor: pointer;
}

.container {
  max-width: 85%;
  margin: auto;
}

footer .container {
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 3fr;
  grid-gap: 30px;
}
@media screen and (max-width: 768px) {
  footer .container {
    grid-template-columns: repeat(2, 1fr);
  }
}

footer .logo span {
  color: var(--orange);
  font-size: 14px;
}
.img {
  width: 200px;
  height: fit-content;
  margin: 20px 0px;
}
footer .logo p {
  color: grey;
  margin: 30px 0 15px 0;
}
footer .logo .icon {
  background-color: var(--orange);
  color: white;
  margin-right: 10px;
}
footer h3 {
  margin-bottom: 40px;
  font-weight: 500;
}
footer ul li {
  margin-bottom: 20px;
}
footer .link li::before {
  content: "⟶";
  color: var(--orange);
  margin-right: 5px;
}

.icon {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.3);
  color: var(--orange);
  transition: 0.5s;
}
.icon:hover {
  cursor: pointer;
  background-color: var(--orange);
  color: #fff;
}
footer .items {
  margin-bottom: 30px;
}
footer .items img {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
  object-fit: cover;
}
footer .items span,
footer .items i {
  font-size: 12px;
  color: var(--orange);
  margin-right: 5px;
  text-transform: capitalize;
}
footer h4 {
  font-weight: 400;
  margin-top: 5px;
}
footer .last ul li {
  display: flex;
  margin-bottom: 50px;
}
footer .last i {
  width: 60px;
  font-size: 20px;
  color: var(--orange);
}
li {
  list-style-type: none;
}
.legal {
  text-align: center;
  padding: 50px 0;
  color: grey;
}
.legal p {
  font-size: 15px;
}
.legal i {
  color: var(--orange);
}

.footer-blur-1 {
  bottom: 0;
  right: 3%;
  width: 26rem;
  height: 12rem;
  filter: blur(200px);
  background: rebeccapurple;
}

.footer-blur-2 {
  bottom: 0;
  left: 3%;
  width: 26rem;
  height: 12rem;
  filter: blur(160px);
  background: rgb(255, 115, 0);
}
