.news {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 0 2rem;
  margin-top: 12rem;
}

.news-header {
  display: flex;
  gap: 3rem;
  margin-bottom: 4rem;
  font-weight: bold;
  font-size: 3rem;
  justify-content: center;
  color: #363d42;
  text-transform: uppercase;
  font-style: italic;
}

.news-card {
  display: flex;
  gap: 1rem;
}

.card {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
  margin-left: 13px;
  min-height: 550px;
}

.shadow {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 36%);
  background-color: #fff;
}

.grid2 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.flexSB {
  display: flex;
  justify-content: space-between;
}

.card img {
  width: 100%;
  height: 230px;
}
.card .text {
  padding: 40px 30px;
}
.card h1 {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  margin: 20px 0;
  transition: 0.5s;
}
.card h1:hover {
  color: var(--orange);
  cursor: pointer;
}

.card a {
  text-decoration: none;
  color: black;
}

.card span {
  text-transform: uppercase;
  font-weight: 500;
  color: var(--orange);
}
.card span label {
  margin-left: 10px;
  color: grey;
  font-size: 14px;
}
.card span:nth-last-child(1) label {
  color: var(--orange);
}
.card p {
  color: grey;
}

@media screen and (max-width: 768px) {
  .flexSB {
    flex-direction: column;
  }
}
